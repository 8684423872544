<script setup lang="ts">
import { computed, onMounted, ref, useId } from 'vue';

import Accordion from '@/components/base/layout/Accordion.vue';
import BaseBodyText from '@/components/base/typography/BaseBodyText.vue';
import Header4 from '@/components/base/typography/Header4.vue';
import Header6 from '@/components/base/typography/Header6.vue';
import SmallBodyText from '@/components/base/typography/SmallBodyText.vue';
import Ingredients from '@/components/pdp/ingredients/Ingredients.vue';
import NutritionFacts from '@/components/pdp/ingredients/NutritionFacts.vue';
import ProductDescription from '@/components/pdp/ProductDescription.vue';
import Carousel from '@/components/pdp-bundle/Carousel.vue';
import CarouselButtons from '@/components/pdp-bundle/CarouselButtons.vue';
import CarouselDots from '@/components/pdp-bundle/CarouselDots.vue';
import money from '@/filters/money';
import { useProductStore } from '@/stores/productDetail';
import { computeSavings, Money } from '@/utils/money';
import { isPhysical } from '@/utils/product';
import { ProductCardData } from '@/utils/productCard';

const { price, variant } = defineProps<{
  index: number;
  price: Money;
  variant: ProductCardData;
}>();

const id = useId();
const productStore = useProductStore(variant.productKey);

const activeCarouselIndex = ref(0);

const product = computed(() => (productStore.rawProduct ? productStore.product : undefined));
const ctVariant = computed(() => product.value?.variants.find((v) => v.sku === variant.sku));
const images = computed(() => product.value?.images || []);
const savings = computed(() => computeSavings(variant.piecePrice, price));

const DESCRIPTIONS = [
  'Will ship immediately after purchase',
  'Ships one month after first box',
  'Ships one month after second box',
];

onMounted(() => {
  productStore.getProduct.execute();
  productStore.getNutritionFacts.execute();
});
</script>

<template>
  <li>
    <div class="flex flex-col-reverse gap-2 lg:flex-col">
      <Header4 headerTag="h3">Box {{ index + 1 }}: {{ variant.name }}</Header4>
      <BaseBodyText class="font-semibold text-neutral-500">
        {{ DESCRIPTIONS[index] }}
      </BaseBodyText>
    </div>

    <div class="flex items-center justify-between mt-6">
      <div class="flex items-baseline gap-1.5">
        <Header6 :class="{ 'text-nuts-red-800': savings.percent > 0 }" headerTag="p">
          {{ money(price) }}
        </Header6>
        <template v-if="savings.percent > 0">
          <SmallBodyText class="line-through text-neutral-600">
            {{ money(variant.piecePrice) }}
          </SmallBodyText>
          <SmallBodyText class="px-2 rounded-full bg-nuts-red-200">
            Save {{ savings.percent }}%
          </SmallBodyText>
        </template>
      </div>

      <div class="flex items-center">
        <img
          aria-hidden="true"
          alt=""
          class="object-contain w-4 h-4 shrink-0"
          src="@/assets/pdp/star-full.svg"
        />
        <SmallBodyText class="ml-1">
          {{ variant.averageRating?.toFixed(1) }}
        </SmallBodyText>
        <SmallBodyText class="ml-1.5">
          <b>{{ variant.totalReviews.toLocaleString() }}</b> Ratings
        </SmallBodyText>
      </div>
    </div>

    <Carousel
      :carouselName="`carousel-${variant.name}`"
      class="mt-6 overflow-hidden rounded-lg lg:mx-0"
      :currentIndex="activeCarouselIndex"
      imageClasses="object-contain w-full max-h-[32rem] h-full"
      :images
    >
      <CarouselButtons
        v-model="activeCarouselIndex"
        edgeBehavior="hidden"
        :slideCount="images.length"
      />
      <CarouselDots
        v-model="activeCarouselIndex"
        class="absolute bottom-2.5 -translate-x-1/2 left-1/2"
        :carouselName="`carousel-${variant.name}`"
        :slideCount="images.length"
      />
    </Carousel>

    <BaseBodyText class="mt-6 text-neutral-600">
      {{ product?.oneLinerDescription || 'A sweet, indulgent start to make Mom feel special.' }}
    </BaseBodyText>
    <Accordion
      class="mt-6 border-t border-solid border-neutral-200"
      defaultExpanded
      headerTag="h4"
      :id="`product-description-${id}`"
    >
      Product Details
      <template #content>
        <ProductDescription
          class="mt-4 mb-2 md:mt-0"
          :description="product?.descriptionHtml"
          :healthTips="product?.healthTipsHtml"
        />
      </template>
    </Accordion>
    <Accordion
      v-if="ctVariant?.ingredients || productStore.nutritionFacts"
      class="border-t border-b border-solid border-neutral-200"
      headerTag="h4"
      :id="`ingredients-${id}`"
    >
      Ingredients
      <template #content>
        <div class="pb-2">
          <Ingredients
            v-if="ctVariant?.ingredients"
            class="border-t border-solid grow border-neutral-200"
            :ingredients="ctVariant.ingredients"
            :isPhysical="isPhysical(ctVariant)"
            :servingsPerContainer="ctVariant?.servingsPerContainer"
          />

          <NutritionFacts
            v-if="productStore.nutritionFacts"
            class="p-3 mt-4 border border-solid rounded border-neutral-200"
            :nutritionFacts="productStore.nutritionFacts"
          />
        </div>
      </template>
    </Accordion>
  </li>
</template>
