<script setup lang="ts">
import UnstyledButton from '@/components/base/UnstyledButton.vue';

const props = defineProps<{ carouselName: string; slideCount: number }>();
const currentIndex = defineModel<number>({ required: true });

const handleKeyDown = (event: KeyboardEvent, index: number) => {
  if (event.key === 'ArrowLeft') {
    const prevIndex = index === 0 ? props.slideCount - 1 : index - 1;
    currentIndex.value = prevIndex;

    const prevButton = document.getElementById(`thumbnail-tab-${props.carouselName}-${prevIndex}`);
    prevButton?.focus();
  } else if (event.key === 'ArrowRight') {
    const nextIndex = index === props.slideCount - 1 ? 0 : index + 1;

    currentIndex.value = nextIndex;
    const nextButton = document.getElementById(`thumbnail-tab-${props.carouselName}-${nextIndex}`);
    nextButton?.focus();
  }
};
</script>

<template>
  <div class="flex justify-center gap-2" role="tablist" aria-label="Control image to display">
    <UnstyledButton
      v-for="(image, index) in slideCount"
      :aria-label="`Go to image ${index + 1}`"
      :aria-selected="currentIndex === index"
      class="w-1.5 h-1.5 transition-colors duration-300 rounded-full"
      :class="index === currentIndex ? 'bg-neutral-500' : 'bg-neutral-300'"
      :id="`thumbnail-tab-${carouselName}-${index}`"
      :key="image"
      role="tab"
      @click="currentIndex = index"
      @keydown="(e: KeyboardEvent) => handleKeyDown(e, index)"
    />
  </div>
</template>
